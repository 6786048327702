import React, { PureComponent } from 'react'
import { Layout } from 'antd'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import {routers} from './routers'
export default class App extends PureComponent {

  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            {
              routers.map(({ path, exact, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  exact={true}
                  render={(props) => {
                    return (
                      <Layout>
                        <Component {...props} />
                      </Layout>
                    )
                  }}
                />
              ))
            }
          </Switch>
        </Layout>
      </BrowserRouter>
    )
  }
}

