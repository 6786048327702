import { AsyncComponent } from '../common/utils/AsyncComponent'

export const routers = [ 
    {
        path: '/',
        exact: true,
        title: '登录',
        component: AsyncComponent(() => import("../user/Login/login")),
    },
    {
        path: '/findPassword',
        exact: true,
        title: '找回密码',
        component: AsyncComponent(() => import("../user/PasswordFind/passwordFind")),  
    },
    {
        path: '/register',
        exact: true,
        title: '找回密码',
        component: AsyncComponent(() => import("../user/Register/register")),
        
    }
];